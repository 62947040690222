define('ember-cli-routing-service/services/routing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
    transitionTo: function transitionTo() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.trigger('transitionTo', args);
    }
  });
});