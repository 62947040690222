define('ember-cli-routing-service/mixins/transition-to-listener', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    routing: _ember['default'].inject.service(),

    setupTransitionToListener: function setupTransitionToListener() {
      var route = this;
      route.get('routing').on('transitionTo', function (args) {
        route.transitionTo.apply(route, args);
      });
    }
  });
});